<template>
  <div>
    <el-header class="reaHead">
      <div class="index-one" @click="homeClick">
        <el-image :src="require('@/assets/logo.png')" alt=""></el-image>
        <div class="index-title">会务管理系统</div>
      </div>
    </el-header>
    <div class="success-img div-all">
      <img class="success-img" src="/image/img.png">
    </div>
    <div class="success-text div-all">
      注册申请提交成功
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    homeClick(){
      this.$router.push("/login");
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .reaHead {
  position: relative;
  width: 100%;
  height: 80px !important;
  display: flex;
  align-items: center;
  //background: rgba(0, 0, 0, 0.6);
  background:#1b63c1 !important;
  color: white;
  justify-content: space-between;
  .index-title {
    font-size: 30px;
  }
  .index-one {
    display: flex;
    position: relative;
    align-items: center;
  }
  .index-one:hover {
    cursor: pointer;
  }

  .el-image__inner {
    width: 55%;
  }
  /deep/ .el-input--prefix .el-input__inner {
    border-radius: 20px;
  }
}

.div-all {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}
.success-img {
  margin-top: 100px;
  height: 10vh;
}
.success-text {
  margin-top: 80px;
  font-size: 25px;
}
</style>
